import React from 'react';
import { providers, auth2 as auth } from 'dc-core';
import { FormattedMessage } from 'react-intl';
import Env from 'stores/env';
import { GeneralInfo, SummaryHeaderInline } from 'common/styledElements';
import ToggleContent from 'common/toggle-content';
import { CONTEXT_BOARD_TYPES, urlPathString } from '../stores/constants';
import stores from 'stores';
import log from 'utils/logger';

export const sanitizeFileName = require('sanitize-filename');

export const MIN_PARTICIPANTS_TO_DISPLAY = 5000; // TODO

export const isStringHTML = str => /\s*(<!doctype|<html|<!--)/i.test(str);

/**
 * Get participant information for CC's, sharees, and special case roles like
 * Only I Sign SHARE
 * @param participantObj.apiResponseKey {string}
 * @param participantObj.className {string}
 * @param participantObj.headerId {string}
 * @param participantObj.role {string}
 * @param members {string}
 * @param toggelContent.componentName {string}
 * @param toggleContent.eventful {object}
 */
export function getParticipants(participantObj, members, toggleContent) {
  let participantsInfo = [];
  //If there is a role provided, return member participant info from
  //participantSets that have it.
  if (participantObj.role) {
    const pSets = members.get(participantObj.apiResponseKey).filter(pSet => {
      return participantObj.role === pSet.get('role');
    });
    pSets.forEach(pSet => {
      pSet.get('memberInfos').forEach(member => {
        participantsInfo.push(member);
      });
    });
  } else {
    participantsInfo = members.get(participantObj.apiResponseKey);
  }

  if (!participantsInfo.length) return null;

  const getDisplayContent = function (participantsArray) {
    return participantsArray
      .map(participant => {
        let name = participant.get('name'),
          email = participant.get('email');
        return name ? name + ' (' + email + ')' : email;
      })
      .join(', ');
  };

  if (participantsInfo.length > MIN_PARTICIPANTS_TO_DISPLAY) {
    const lessContent = getDisplayContent(participantsInfo.slice(0, MIN_PARTICIPANTS_TO_DISPLAY)),
      restContent = ', ' + getDisplayContent(participantsInfo.slice(MIN_PARTICIPANTS_TO_DISPLAY));
    return (
      <GeneralInfo className={participantObj.className}>
        <SummaryHeaderInline>
          <FormattedMessage id={participantObj.headerId} />:
        </SummaryHeaderInline>
        <ToggleContent
          lessContent={lessContent}
          restContent={restContent}
          componentName={toggleContent.componentName}
          eventful={toggleContent.eventful}
        />
      </GeneralInfo>
    );
  } else {
    if (!participantsInfo.length) return null;

    return (
      <GeneralInfo className={participantObj.className}>
        <SummaryHeaderInline>
          <FormattedMessage id={participantObj.headerId} />:
        </SummaryHeaderInline>
        {getDisplayContent(participantsInfo)}
      </GeneralInfo>
    );
  }
}

/**
 * Function to route to Native RS for modern RS enabled user
 */
export const fetchAssetUriforModernRequestSignatures = async () => {
  const agreementId = stores.agreement.id;
  const rsProvider = await providers['pdf-requestsignatures']();
  return rsProvider.createDraftAgreementFromLibraryTemplate(agreementId);
};

/**
 * Handler to open a new tab with the url passed in and fire the analytics.clicked event
 * @param {String} url url of the page the action need to redirect to
 * @param {Function} options.analytics analytics instance bound to a "type"
 * @param {String} options.analyticsEventType - "type" of analytics, event will be "clicked"
 * @param {String} options.openInNewTab - ability to open in a new tab if required (Eg: View & Sign action)
 * @param {String} options.useSignURL - always use the sign URL
 */
export function redirectAction(url, options = {}) {
  const newTabKey = options.userEvent && (options.userEvent.ctrlKey || options.userEvent.metaKey);

  // DCES-4468454: Fix for opening new tab for MSTeamEmbedded use case
  // In the MSTeamEmbedded use case, window.open() does not return a proxy window object, so we need to use
  // window.open with the url directly
  url = getLocationHref(url, options);
  window.open(url, options.openInNewTab || newTabKey ? '_blank' : '_self');
}

export function getLocationHref(url, options = {}) {
  if (Env.isHostEnvSign || options.useSignURL) {
    return url;
  } else {
    return prepURL(url, options);
  }
}

export function getDestination(url) {
  if (url.indexOf(urlPathString.AGREEMENT_ID) !== -1) return 'COMPOSE';
  else if (url.indexOf(urlPathString.EDIT_ID) !== -1) return 'MODIFY_IN_FLIGHT';
  else if (url.indexOf(urlPathString.DOCUMENT_EDIT) !== -1) return 'AUTHORING';
  else return 'OTHERS';
}

/**
 * In case of DC Web, we need to construct the URL in a way to display the pages inside of iFrame
 * Old URL (Eg): https://previewusers.na1.echosignawspreview.com/account/reusableDocument?aid=CBJCH...cPsi&locale=en&client_id=p47M9L6G5N4P5A&dc_remove=true
 * New URL (Eg): https://dc.dev.dexilab.acrobat.com/link/signatures/?app!versions=latest&sign_integrated_into_dc_web=true&signUri=%2Faccount%2FreusableDocument%3Faid%3DCBJCHBCAABAADLhuQ5Mn7DSWhbU8ChiL6MqeTmRXllZ2%26pid%3DCBJCHBCAABAAf0YJaH6IWi-sTzvffh5bioZoicRKcPsi%26locale%3Den%26client_id%3Dp47M9L6G5N4P5A
 * Get the relative path of the page from the Sign URL, and append that to the DC Web URL with query parameter signUri (needs to be encoded).
 *
 * @param {String} url - URL that needs to be converted into DC Web compliant value (to be displayed inside an iFrame).
 * @param {boolean} options.calculateRelativePath - should relative url calculated.
 * @param {string} options.assetUri - transient file urn id for the selected agreement.
 * @returns {String} DC Web compliant URL.
 */
export function prepURL(url, options) {
  let calculateRelativePath =
    options.calculateRelativePath !== undefined ? options.calculateRelativePath : true;
  let relativeURLPath = url;

  if (calculateRelativePath) {
    relativeURLPath = '/' + url.split('/').slice(3).join('/');
  }

  const encodedPath = encodeURIComponent(relativeURLPath);
  const currentURL = window.location.href,
    signPath = window.location.origin + '/link/signatures/';
  if (options.assetUri) {
    let locationQueryParams = new URLSearchParams(window.location.search);
    if (locationQueryParams.get('transientId')) {
      locationQueryParams.delete('transientId');
    }
    return (
      window.location.origin +
      '/link/file' +
      `?${locationQueryParams.toString()}` +
      `&viewer!megaVerb=verb-pdf-requestsignatures&viewer!verb=verb-pdf-requestsignatures&uri=${options.assetUri}&transientId=${options.assetUri}`
    );
  }
  if (currentURL.indexOf('?') === -1) {
    return signPath + '?signUri=' + encodedPath;
  } else {
    if (currentURL.indexOf('signUri') === -1) {
      const extraParams = options.disableModernRS ? '&disableModernRS=true' : '';
      return signPath + window.location.search + '&signUri=' + encodedPath + extraParams;
    } else {
      let hostURL = new URL(currentURL),
        queryParams = hostURL.searchParams;

      queryParams.set('signUri', relativeURLPath);
      if (options.disableModernRS) {
        queryParams.set('disableModernRS', 'true');
      }
      return signPath + hostURL.search;
    }
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
// https://w3c.github.io/uievents-code/#keyboard-key-codes
export const KEYS = {
  COMMA: ',',
  ENTER: 'Enter',
  TAB: 'Tab',
  SEMICOLON: ';',
  SPACE: ' ',
  SPACEBAR: 'Spacebar',

  isComma: e => {
    let key = e.key || e.code;
    return key === KEYS.COMMA;
  },
  isEmailSep: e => {
    return (
      KEYS.isComma(e) || KEYS.isEnter(e) || KEYS.isSemicolon(e) || KEYS.isSpace(e) || KEYS.isTab(e)
    );
  },
  isEnter: e => {
    return (e.key || e.code) === KEYS.ENTER;
  },
  isSemicolon: e => {
    return (e.key || e.code) === KEYS.SEMICOLON;
  },
  isTab: e => {
    return (e.key || e.code) === KEYS.TAB;
  },
  isSpace: e => {
    let key = e.key || e.code;
    return key === KEYS.SPACE || key === KEYS.SPACEBAR;
  }
};

/**
 * Method to return the extension of a file based on its mimetype
 * Reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 * Reference: https://www.openoffice.org/framework/documentation/mimetypes/mimetypes.html
 * @param {String} mimeType mimetype of a file
 * @returns {String} extension of the file
 */
export function getFileExtension(mimeType) {
  let extension = null;
  switch (mimeType) {
    case 'application/pdf':
      extension = '.pdf';
      break;

    case 'text/html':
      extension = '.html';
      break;

    case 'text/plain':
      extension = '.txt';
      break;

    case 'image/png':
      extension = '.png';
      break;

    case 'image/jpeg':
      extension = '.jpg';
      break;

    case 'image/bmp':
      extension = '.bmp';
      break;

    case 'image/gif':
      extension = '.gif';
      break;

    case 'image/tiff':
      extension = '.tiff';
      break;

    case 'application/msword':
      extension = '.doc';
      break;

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      extension = '.docx';
      break;

    case 'application/vnd.oasis.opendocument.text':
      extension = '.odt';
      break;

    case 'application/vnd.ms-excel':
      extension = '.xls';
      break;

    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      extension = '.xlsx';
      break;

    case 'application/vnd.oasis.opendocument.spreadsheet':
      extension = '.ods';
      break;

    case 'application/vnd.ms-powerpoint':
      extension = '.ppt';
      break;

    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      extension = '.pptx';
      break;

    case 'application/vnd.oasis.opendocument.presentation':
      extension = '.odp';
      break;

    case 'text/rtf':
    case 'application/rtf':
      extension = '.rtf';
      break;

    case 'text/csv':
      extension = '.csv';
      break;

    default:
      break;
  }

  return extension;
}

/**
 * Method to return the drill down URL for the manage page
 * @param {String} type type of the parent (megasign, webform)
 * @param {String} state state of the child agreement
 * @param {String} parent_id the agreement ID of the parent
 * @returns {String} the redirectURL of the manage page
 */
export const getAgreementDrillDownUrl = (type, state, parent_id, shared) => {
  const drilldownURL =
    window.location.origin +
    '/public/agreements/#agreement_type=' +
    type +
    '&agreement_state=' +
    state +
    '&parent_id=' +
    parent_id +
    '&shared=' +
    shared;
  return drilldownURL;
};

/**
 * Method that returns date of latest ownership change
 * @param events - list of all events for asset
 * @returns {null|String} date or null if ownership was never changed
 */
export function getLatestOwnerChangedDate(events) {
  const sortedOwnerChangedEvents = events
    .filter(event => event.get('type') === 'OWNER_CHANGED')
    .sort((a, b) => new Date(b.get('date')) - new Date(a.get('date')));
  const [latestOwnerChangedEvent] = sortedOwnerChangedEvents;
  if (latestOwnerChangedEvent) {
    return latestOwnerChangedEvent.get('date');
  }
  return null;
}

/**
 * Replace in string at index
 *
 * @param str {string} original string
 * @param at {integer} 0-based start index to be replaced
 * @param len {integer} length of string to be replaced
 * @param replacement {string} replacement string
 * @return {string}
 */
export const stringReplaceAt = (str, at, len, replacement) => {
  return str.substr(0, at) + replacement + str.substr(at + len);
};

/**
 * Get root path for agreements / manage page
 *
 * @param isDCWeb {boolean} Is containing app DCWeb?
 * @returns {string}
 */
export function getAgreementsRootPath(isDCWeb) {
  const dcPath = '/link/documents/agreements/';
  const signPath = '/public/agreements/';

  return isDCWeb ? dcPath : signPath;
}

export const isModernRSDefaultExperienceDisabled = async () => {
  try {
    const userProvider = await providers.user();
    const signPrefs = await userProvider.getPreferences('dcweb/sign');
    return signPrefs?.['modern_rs_default_ex'] === false;
  } catch (err) {
    // If failure, simply assume it's not set.
    return false;
  }
};

export const isPDFCompatibleWithModernRS = async assetUri => {
  const rsProvider = await providers['pdf-requestsignatures']();
  return rsProvider.isPDFCompatible(assetUri);
};

// Is this DC Web, are they Acrobat Pro or Acrobat Standard, is the user country code JP and the 'dc-web-jp-tag' feature flag enabled
export const isJapanTagEnabled = async () => {
  // Ensure we are even able to try and check for the user
  if (!Env.isDCWeb) {
    return false;
  }
  // And the flag is enabled
  if (!stores.Floodgate.hasJapanTagEnabled()) {
    return false;
  }
  // Get acrobat plus or acrobat std limits following the patterns in dc-core UserAPI.js
  const userProvider = await providers.user();
  const acrobatLimits = await userProvider.getLimitsAcrobat();
  const isAcrobatPlusOrStd =
    acrobatLimits.acrobat_pro === true || acrobatLimits.acrobat_std === true;
  if (!isAcrobatPlusOrStd) {
    return false;
  }
  // And the  user is in Japan
  try {
    const userProfile = auth.getUserProfile();
    return userProfile.countryCode === 'JP';
  } catch (err) {
    log.info('isJapanTagEnabled:auth.getUserProfile() exception', err);
  }
  // Or has a japan locale in sign profile
  const userLocale = stores?.User?.user?.get('locale');
  return userLocale === 'ja_JP';
};

/**
 * Utility method to determine if agreement is in sender view and in flight
 *
 * The next condition will check:
 * - for in-flight agreements
 * - for originator only (if the sender is the API caller)
 *
 * In-flight agreements determine if the agreement is still out to be completed.
 * Allowed statuses for In-flight agreements are:
 *  OUT_FOR_SIGNATURE, OUT_FOR_APPROVAL, OUT_FOR_DELIVERY, OUT_FOR_ACCEPTANCE,
 *  OUT_FOR_FORM_FILLING, OUT_FOR_WITNESSING
 *
 * @returns true if agreement is in sender view and in flight
 */
export const isSenderViewOfInFlightAgreement = () => {
  return stores.agreement.members.isSender() && stores.agreement.isOutForCompletion();
};

/**
 * Logic to determine if a recipient is unfinished (i.e has no ACTION_COMPLETED* event)
 * and has a bounce event (for now, just EMAIL_BOUNCED event).
 * This logic will be used to determine if a bounced icon should be displayed next to a
 * recipient or not.
 *
 * Note: A recipient with a bounced event can still manage to complete the agreement,
 * for eg: via the Manage page.
 *
 * @returns true if recipient with input events is unfinished and has bounce event; false otherwise
 */
export function unfinishedRecipientHasBounceEvent(memberEvents) {
  const memberCompletedAgreement =
    memberEvents?.some(m => m.attributes.type.includes('ACTION_COMPLETED')) ?? false;

  if (memberCompletedAgreement) {
    return false;
  }

  const bouncedEventExistsForMember =
    memberEvents?.some(m => m.attributes.type === 'EMAIL_BOUNCED') ?? false;
  return bouncedEventExistsForMember;
}

/**
 * Check if any member of the participant set has completed the agreement.
 * This would imply that the recipient group is Done.
 *
 * @param {*} participantSet
 * @returns true if any member of the participant set has completed the agreement; false otherwise
 */
export function checkIfAnyMemberOfGroupIsDone(participantSet) {
  if (!participantSet) {
    return false;
  }

  return [
    stores.Api.Agreements.Members.STATUS.COMPLETED,
    stores.Api.Agreements.Members.STATUS.WAITING_FOR_OTHERS
  ].includes(participantSet.get('status'));
}

/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 */
export function getRelativeTimeString(date, lang = 'en') {
  // Allow dates or times to be passed
  const timeMs = typeof date === 'number' ? date : date.getTime();

  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

  // Array reprsenting one minute, hour, day, week, month, etc in seconds
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];

  // Array equivalent to the above but in the string representation of the units
  const units = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex(cutoff => cutoff > Math.abs(deltaSeconds));

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });
  return rtf.format(Math.round(deltaSeconds / divisor), units[unitIndex]);
}

/**
 * Returns current time in format "yyyy.MM.dd.HH.mm"
 * @returns {string}
 */
export function getCurrentTimestamp() {
  const now = new Date();
  return [
    now.getFullYear(),
    String(now.getMonth() + 1).padStart(2, '0'),
    String(now.getDate()).padStart(2, '0'),
    String(now.getHours()).padStart(2, '0'),
    String(now.getMinutes()).padStart(2, '0')
  ].join('.');
}

// Fix for DCES-4439246, DCES-4442844 Child Agreements with different roles are displayed as "In Progress"/ "Completed" separately
// With new SiB as multiple roles are supported the "In Progress" child agreements have different statuses like OUT_FOR_* and
// "Completed" child agreements with different statuses like APPROVED, SIGNED etc.
/**
 * Agreements count needs to be sum of all the agreements with OUT_FOR_STATUSES and COMPLETED_STATUSES
 * @param statusCountMap {Map} of agreement statuses to number of child agreements in that status
 * @param statusList {Array} of defined statuses to extract from the map in calculating count
 * @returns number of the child agreements in In Progress/ Completed status
 */
export function statusCounter(statusCountMap, statusList) {
  return Object.entries(statusCountMap)
    .filter(([key]) => statusList.includes(key))
    .reduce((count, [key, val]) => count + val, 0);
}

export function isAgreementViewRestrictionApplied() {
  return (
    stores.agreementKind === CONTEXT_BOARD_TYPES.AGREEMENT &&
    stores.Floodgate.hasAgreementViewRestrictionEnabled() &&
    stores.agreement.isViewRestrictionApplied()
  );
}
