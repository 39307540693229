import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import Button from '@react/react-spectrum/Button';
import Checkbox from '@react/react-spectrum/Checkbox';
import Textfield from '@react/react-spectrum/Textfield';
import Wait from '@react/react-spectrum/Wait';
import Link from '@react/react-spectrum/Link';
import Popover from '@react/react-spectrum/Popover';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Help from 'dc-icons/global/s_help_18.svg';

import { DialogFooter, HelpButton } from 'common/styledElements';
import ExpandableToast from 'common/toast-x';
import { analyticsFor } from 'utils/analytics';
import { WithToastMessage } from 'as-ducati-core';
import stores from 'stores';
import { AUTH_METHODS } from './editAuthentication';
import { getHelpXUrl } from 'as-ducati-utils';

const LEARN_MORE_HELPX_PATH = 'idcheck_config';

const isValidEmail = email => stores.Api.Utils.isValidEmail(email);
const analytics = analyticsFor(analyticsFor.MEMBERS, 'identity_check');
const NameMatchCriteria = {
  DISABLED: 'DISABLED',
  EXACT: 'EXACT',
  PARTIAL_MAXIMAL: 'PARTIAL_MAXIMAL'
};

const TextInput = styled(Textfield)`
  && {
    display: inline-block;
    width: 95%;
    margin: 0px 0 0 0;
  }
`;

const Tab = styled.div`
  padding-left: 20px;
`;

const StyledContainer = styled.div`
  overflow-x: hidden; // prevent x axis scrollbar
  min-height: ${props => (props.height ? `${props.height}px` : '350px')};
  width: ${props => (props.width ? `${props.width}px` : '100%')};

  &&& .spectrum-FieldGroup {
    display: block;

    label {
      width: 100%;
    }
  }
`;

export const Heading = styled.div`
  display: inline-block;
  font-size: 80%;
  color: rgb(112, 112, 112);
`;

export const EmailButtonSpace = styled.div`
  display: block;
`;

const StyledWait = styled(Wait)`
  z-index: 10;
`;

const StyledPopover1 = styled(Popover)`
  &&& {
    .spectrum-Popover-tip:after {
      background-color: #747474;
    }
    .spectrum-Dialog-content {
      font-size: 12px;
      font-weight: 400;
      background-color: #747474;
      color: #fff;
    }
    .spectrum-Popover-tip {
      top: 36px;
      transform: rotate(90deg);
      left: -8%;
    }
    min-width: 200px;
    left: 1292px !important;
  }
`;

const StyledPopover2 = styled(Popover)`
  &&& {
    .spectrum-Popover-tip:after {
      background-color: #747474;
    }
    .spectrum-Dialog-content {
      font-size: 12px;
      font-weight: 400;
      background-color: #747474;
      color: #fff;
    }
    .spectrum-Popover-tip {
      top: 36px;
      transform: rotate(90deg);
      left: -8%;
    }
    min-width: 200px;
    left: 1292px !important;
    top: 311px; !important
  }
`;

const StyledHelpIcon = styled(HelpButton)`
  &&& {
    float: right;
    position: relative;
    margin-left: -20px;
  }
  & > svg {
    width: 14px;
    height: 14px;
  }
`;

const HelpIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 10px; // Space between the checkbox text and the help icon
`;

EmailButtonSpace.propTypes = { children: PropTypes.node };

@inject('agreement', 'stores')
@observer
class IdentityCheck extends Component {
  @observable isApplyEmailMatching;
  @observable isAllowRegisteredAlternativeEmailAddress;
  @observable isAllowCustomAlternativeEmailAddress;
  @observable isPartialNameMatching;
  @observable error = null;
  @observable warning = null;
  @observable alternateEmailsValue = '';
  @observable alternateNameValue = '';
  @observable isApplyNameMatching = false;
  @observable loading = false;

  @observable emailMatchCheckboxEnabled;
  @observable nameMatchCheckboxEnabled;
  @observable isAlternativeEmailAllowed;
  @observable isPartialNameMatchingSettingEnabled;

  initialState = {};
  helpPopupRef = React.createRef();

  constructor(props) {
    super(props);
    this.member = this.props.member;
    this.presetData(this.props.member);
    this.applyIdentityPolicyBasedOnSetting();
    this.changeAlternativeEmails = this.changeAlternativeEmails.bind(this);
    this.setError = this.setError.bind(this);
    this.setWarning = this.setWarning.bind(this);
    this.saveInitialState();
    this.dialogRef = React.createRef();
  }

  render() {
    const height = 50;
    return (
      <StyledContainer height={height}>
        {this.loading && <StyledWait centered />}
        {this.renderEmailMatchingCheckbox()}
        {this.isApplyEmailMatching && this.renderAlternativeEmailSettings()}
        {this.renderNameMatchingCheckbox()}
        {this.isApplyNameMatching && this.renderAlternativeNameSettings()}
        {this.warning && (
          <ExpandableToast
            variant="warning"
            compact
            closable
            message={this.warning.message}
            onClose={() => this.setWarning(null)}
          />
        )}
        {this.renderDialogFooter()}
      </StyledContainer>
    );
  }

  renderEmailMatchingCheckbox() {
    const { formatMessage } = this.props.stores.Intl;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          label={formatMessage({ id: 'identity_check.apply_email_matching_text' })}
          onChange={value => this.changeApplyEmailMatching(value)}
          disabled={!this.emailMatchCheckboxEnabled}
          checked={this.isApplyEmailMatching}
        />
        <HelpIconWrapper>
          <OverlayTrigger
            trigger={'click'}
            placement="left"
            container={() => this.helpPopupRef.current}
          >
            <StyledHelpIcon
              variant="tool"
              icon={<Help />}
              aria-label={formatMessage({ id: 'actions.help' })}
              aria-haspopup={'dialog'}
            />
            <StyledPopover1
              variant={'default'}
              role={'dialog'}
              style={{ padding: '8px', width: '130px', backgroundColor: '#747474' }}
              aria-labelledby={'hideToolTip'}
            >
              <span id={'hideToolTip'}>
                {formatMessage(
                  { id: 'identity_check.email_help_text' },
                  {
                    learn_more_link: (
                      <Link
                        href={getHelpXUrl(LEARN_MORE_HELPX_PATH)}
                        target="_blank"
                        variant="quiet"
                      >
                        {formatMessage({ id: 'visibility.hide.help.learn_more' })}
                      </Link>
                    )
                  }
                )}
              </span>
            </StyledPopover1>
          </OverlayTrigger>
        </HelpIconWrapper>
      </div>
    );
  }

  renderAlternativeEmailSettings() {
    const { formatMessage } = this.props.stores.Intl;
    return (
      <Tab>
        <Checkbox
          label={formatMessage({
            id: 'identity_check.registered_alternate_email_matching_text'
          })}
          onChange={value => this.changeAllowRegisteredAlternativeEmailAddress(value)}
          disabled={!this.isAlternativeEmailAllowed}
          checked={this.isAllowRegisteredAlternativeEmailAddress}
        />
        <Checkbox
          label={formatMessage({ id: 'identity_check.custom_alternate_email_matching_text' })}
          onChange={value => this.changeAllowCustomAlternativeEmailAddress(value)}
          disabled={!this.isAlternativeEmailAllowed}
          checked={this.isAllowCustomAlternativeEmailAddress}
        />
        <Tab>
          <TextInput
            value={this.alternateEmailsValue}
            onChange={this.changeAlternativeEmails}
            invalid={!!this.error}
            disabled={!this.isAllowCustomAlternativeEmailAddress}
          />
          <Heading>
            {formatMessage({ id: 'identity_check.alternate_email_matching_text_help' })}
          </Heading>
          {this.error && (
            <ExpandableToast
              variant="error"
              compact
              closable
              message={this.error.message}
              onClose={() => this.setError(null)}
            />
          )}
        </Tab>
      </Tab>
    );
  }

  renderNameMatchingCheckbox() {
    const { formatMessage } = this.props.stores.Intl;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          label={formatMessage({ id: 'identity_check.apply_name_matching_text' })}
          onChange={value => this.changeApplyNameMatching(value)}
          disabled={!this.nameMatchCheckboxEnabled}
          checked={this.isApplyNameMatching}
        />
        <HelpIconWrapper>
          <OverlayTrigger
            trigger={'click'}
            placement="left"
            container={() => this.helpPopupRef.current}
          >
            <StyledHelpIcon
              variant="tool"
              icon={<Help />}
              aria-label={formatMessage({ id: 'actions.help' })}
              aria-haspopup={'dialog'}
            />
            <StyledPopover2
              variant={'default'}
              role={'dialog'}
              style={{ padding: '8px', width: '130px', backgroundColor: '#747474' }}
              aria-labelledby={'hideToolTip'}
            >
              <span id={'hideToolTip'}>
                {formatMessage(
                  { id: 'identity_check.name_help_text' },
                  {
                    learn_more_link: (
                      <Link
                        href={getHelpXUrl(LEARN_MORE_HELPX_PATH)}
                        target="_blank"
                        variant="quiet"
                      >
                        {formatMessage({ id: 'visibility.hide.help.learn_more' })}
                      </Link>
                    )
                  }
                )}
              </span>
            </StyledPopover2>
          </OverlayTrigger>
        </HelpIconWrapper>
      </div>
    );
  }

  renderAlternativeNameSettings() {
    const { formatMessage } = this.props.stores.Intl;
    return (
      <Tab>
        <div onClick={() => !this.warning && this.nameChangeWarningCheck()}>
          <TextInput
            value={this.alternateNameValue}
            onChange={value => this.changeAlternateName(value)}
            style={{ pointerEvents: 'none' }}
            disabled={true}
          />
        </div>
        <Checkbox
          label={formatMessage({ id: 'identity_check.require_name_matching.allow_text' })}
          onChange={value => this.changePartialNameMatching(value)}
          disabled={!this.isApplyNameMatching || !this.isPartialNameMatchingSettingEnabled}
          checked={this.isPartialNameMatching}
        />
      </Tab>
    );
  }

  renderDialogFooter() {
    const { formatMessage } = this.props.stores.Intl;
    return (
      <DialogFooter>
        <Button onClick={() => this.props.toggleOverlay(false)} variant="secondary">
          {formatMessage({ id: 'cancel.title' })}
        </Button>
        <Button
          variant="cta"
          disabled={!!this.error || !this.isInputChanged()}
          onClick={() => this.submit()}
        >
          {formatMessage({ id: 'confirm' })}
        </Button>
      </DialogFooter>
    );
  }

  @action
  changeAlternativeEmails(event) {
    const { formatMessage } = this.props.stores.Intl;
    this.alternateEmailsValue = event; // Update input value
    const emails = this.getEmailsArray(this.alternateEmailsValue);
    const isValid = emails.length === 0 || emails.every(email => isValidEmail(email));
    this.setError(
      isValid ? null : formatMessage({ id: 'identity_check.invalid_alternate_email_error' })
    );
  }

  getEmailsArray(value) {
    return value
      .split(/[;, ]+/)
      .map(email => email.trim())
      .filter(email => email);
  }

  @action
  setError(error) {
    if (typeof error === 'string') {
      this.error = { message: error };
    } else {
      this.error = error;
    }
  }

  @action
  nameChangeWarningCheck() {
    const { formatMessage } = this.props.stores.Intl;
    if (!this.alternateNameValue) {
      this.setWarning(formatMessage({ id: 'identity_check.no_name_warning' }));
    } else {
      this.setWarning(formatMessage({ id: 'identity_check.name_update_warning' }));
    }
  }

  @action
  setWarning(warning) {
    if (typeof warning === 'string') {
      this.warning = { message: warning };
    } else {
      this.warning = warning;
    }
  }

  /**
   * User clicked on 'confirm' after selecting a new authentication.
   */
  @action
  submit() {
    const EMAIL_MATCH_SUPPORTED_AUTH = [AUTH_METHODS.DIG_ID, AUTH_METHODS.NONE];
    const secOpts = this.member.get('securityOption');
    const authenticationMethod = secOpts && secOpts.authenticationMethod;
    const nameMatchCriteria = this.getNameMatchCriteria();

    // Create the identityCheckInfo object with the required properties
    const identityCheckInfo = {
      ...(EMAIL_MATCH_SUPPORTED_AUTH.includes(authenticationMethod) && {
        emailMatch: {
          requireEmailMatching: this.isApplyEmailMatching,
          allowRegisteredAlternateEmail: this.isAllowRegisteredAlternativeEmailAddress,
          allowCustomAlternateEmail: this.isAllowCustomAlternativeEmailAddress,
          alternateEmails: this.getEmailsArray(this.alternateEmailsValue)
        }
      }),
      nameMatch: {
        nameMatchCriteria: nameMatchCriteria
      }
    };

    this.loading = true;
    // Get the security option sub-model from the member's model (with blank attributes)
    const securityOptionModel = this.props.member.getModel().securityOptions;
    // Save the identityMatching object
    securityOptionModel
      .save({ identityCheckInfo }) // Pass the object directly here
      .then(() => {
        let secOpt = this.props.member.get('securityOption');
        secOpt.identityCheckInfo = identityCheckInfo;
        this.props.member.set({ securityOption: secOpt });
        this.editSuccess();
      })
      .catch(e => {
        // Handle the error accordingly
        this.editError(e);
      });
  }

  editSuccess() {
    const { formatMessage } = this.props.stores.Intl;
    const successMsg = formatMessage({ id: 'identity_check.success' });
    analytics.success();
    this.props.toggleOverlay(false);
    this.props.showToast({ text: successMsg, type: 'success' });
  }

  @action
  editError(e) {
    analytics.failed(e);
    this.loading = false;
    this.props.toggleOverlay(false);
    this.props.showToast(e);
  }

  validateEmailInput() {
    // Trim leading and trailing semicolons and spaces before splitting into an array.
    const emails = this.getEmailsArray(this.alternateEmailsValue.replace(/^[; ]+|[; ]+$/g, ''));
    return emails.length > 0 && emails.every(email => isValidEmail(email));
  }

  isInputChanged() {
    const emailMatchChanged = this.isApplyEmailMatching !== this.initialState.isApplyEmailMatching;
    const allowRegisteredAltEmailChanged =
      this.isAllowRegisteredAlternativeEmailAddress !==
      this.initialState.isAllowRegisteredAlternativeEmailAddress;
    const customAltEmailChanged =
      this.isAllowCustomAlternativeEmailAddress !==
      this.initialState.isAllowCustomAlternativeEmailAddress;
    const hasValidAltEmails = !!this.alternateEmailsValue && this.validateEmailInput();
    const altEmailValueChanged =
      this.alternateEmailsValue !== this.initialState.alternateEmailsValue;
    const customAltEmailValueChanged =
      customAltEmailChanged &&
      (!this.isAllowCustomAlternativeEmailAddress ||
        (hasValidAltEmails &&
          this.alternateEmailsValue !== this.initialState.alternateEmailsValue));

    const nameMatchChanged = this.isApplyNameMatching !== this.initialState.isApplyNameMatching;
    const partialNameMatchChanged =
      this.isPartialNameMatching !== this.initialState.isPartialNameMatching;
    const alternateNameChanged = this.alternateNameValue !== this.initialState.alternateNameValue;

    if (this.isAllowCustomAlternativeEmailAddress && !hasValidAltEmails) {
      return false;
    }

    return (
      emailMatchChanged ||
      allowRegisteredAltEmailChanged ||
      customAltEmailValueChanged ||
      partialNameMatchChanged ||
      alternateNameChanged ||
      nameMatchChanged ||
      altEmailValueChanged
    );
  }

  saveInitialState() {
    // Save the initial state for comparison
    this.initialState = {
      isApplyEmailMatching: this.isApplyEmailMatching,
      isAllowRegisteredAlternativeEmailAddress: this.isAllowRegisteredAlternativeEmailAddress,
      isAllowCustomAlternativeEmailAddress: this.isAllowCustomAlternativeEmailAddress,
      isPartialNameMatching: this.isPartialNameMatching,
      alternateEmailsValue: this.alternateEmailsValue,
      alternateNameValue: this.alternateNameValue,
      isApplyNameMatching: this.isApplyNameMatching
    };
  }

  @action.bound
  changeApplyEmailMatching(value) {
    this.isApplyEmailMatching = value;
  }

  @action
  changeAllowRegisteredAlternativeEmailAddress(value) {
    this.isAllowRegisteredAlternativeEmailAddress = value;
  }

  @action
  changeAllowCustomAlternativeEmailAddress(value) {
    this.isAllowCustomAlternativeEmailAddress = value;
  }

  @action
  changeApplyNameMatching(value) {
    this.isApplyNameMatching = value;
  }

  @action
  changeAlternateName(value) {
    this.alternateNameValue = value;
  }

  @action
  changePartialNameMatching(value) {
    this.isPartialNameMatching = value;
  }

  getNameMatchCriteria() {
    if (!this.isApplyNameMatching) {
      return NameMatchCriteria.DISABLED;
    }
    return this.isPartialNameMatching ? NameMatchCriteria.PARTIAL_MAXIMAL : NameMatchCriteria.EXACT;
  }

  applyIdentityPolicyBasedOnSetting() {
    const identityCheckPlugin = window?.App?.Env?.plugins?.identityCheck;
    const nameMatchPolicy = identityCheckPlugin.features.nameMatchingPolicy;
    const emailMatchPolicy = identityCheckPlugin.features.emailMatchingPolicy;

    // Handle email matching policy
    this.emailMatchCheckboxEnabled = this.isMatchCheckboxEnabled(
      emailMatchPolicy,
      this.isApplyEmailMatching
    );
    // Handle name matching policy
    this.nameMatchCheckboxEnabled = this.isMatchCheckboxEnabled(
      nameMatchPolicy,
      this.isApplyNameMatching
    );

    //override name matching if name is not available
    this.isApplyNameMatching =
      this.isApplyNameMatching &&
      !!this.alternateNameValue &&
      this.alternateNameValue.trim() !== '';

    //check edit name setting
    this.nameMatchCheckboxEnabled =
      this.nameMatchCheckboxEnabled &&
      !!this.alternateNameValue &&
      this.alternateNameValue.trim() !== '';

    // Additional settings
    this.isAlternativeEmailAllowed = identityCheckPlugin?.features?.isAlternativeEmailMatchAllowed;
    this.isPartialNameMatchingSettingEnabled =
      identityCheckPlugin?.features?.isPartialNameMatchAllowed ?? false;
  }

  isMatchCheckboxEnabled(policy, isApplyMatching) {
    return (
      policy === 'ALLOWED' ||
      (!isApplyMatching && policy === 'REQUIRED') ||
      (isApplyMatching && policy === 'DISABLED')
    );
  }

  presetData(member) {
    const secOpt = member.get('securityOption');
    const identityCheckInfo = secOpt?.identityCheckInfo;
    const emailMatch = identityCheckInfo?.emailMatch;
    const nameMatchCriteria = identityCheckInfo?.nameMatch?.nameMatchCriteria;

    this.alternateNameValue = this.member.get('name');

    this.isApplyNameMatching =
      nameMatchCriteria === NameMatchCriteria.PARTIAL_MAXIMAL ||
      nameMatchCriteria === NameMatchCriteria.EXACT;
    this.isPartialNameMatching = nameMatchCriteria === NameMatchCriteria.PARTIAL_MAXIMAL;

    this.isApplyEmailMatching = emailMatch?.requireEmailMatching || false;
    this.isAllowRegisteredAlternativeEmailAddress =
      emailMatch?.allowRegisteredAlternateEmail || false;
    this.isAllowCustomAlternativeEmailAddress = emailMatch?.allowCustomAlternateEmail || false;
    this.alternateEmailsValue = (emailMatch?.alternateEmails || []).filter(Boolean).join(',') || '';
  }
}

export default WithToastMessage(IdentityCheck);
