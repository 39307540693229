import styled from 'styled-components';
import Button from '@react/react-spectrum/Button';
import Alert from '@react/react-spectrum/Icon/Alert';
import Heading from '@react/react-spectrum/Heading';
import RadioGroup from '@react/react-spectrum/RadioGroup';
import Dialog from '@react/react-spectrum/Dialog';
import Textfield from '@react/react-spectrum/Textfield';
import Wait from '@react/react-spectrum/Wait';
import Progress from '@react/react-spectrum/Progress';
import Checkmark from 'dc-icons/Sign/s_checkmarkcircle_green_18_d.svg';
import userLock from '@react/react-spectrum/Icon/UserLock';
import AssetModified from 'dc-icons/Sign/S_AssetsModified_18_N.svg';
import PropTypes from 'prop-types';
import theme from './theme';

// relax some props to suppress Jest warnings
// Use sparingly!
Dialog.propTypes.confirmLabel = PropTypes.any; // eslint-disable-line
Button.propTypes.label = PropTypes.any; // eslint-disable-line

export const ActionSection = styled.section`
  flex-basis: 100%;
  flex: auto;
`;

export const Divider = styled.hr`
  border: 0;
  height: 0;
  width: 100%;
  display: block;
  margin: 7px 0 7px -6px;
  padding-right: 12px;
  clear: both;

  .spectrum--light & {
    border-top: 1px solid ${theme.global_color_gray_300};
  }

  .spectrum--dark & {
    border-top: 1px solid #4a4a4a;
  }
`;

export const ClearBoth = styled.div`
  clear: both;
`;

/**
 * Right-aligned buttons container
 */
export const DialogFooter = styled.div.attrs({
  className: 'spectrum-Dialog-footer'
})`
  /* increase specificity to override spectrum style */
  && {
    overflow: hidden;
    margin: 25px 0px 0px 0px;
    background-color: transparent !important;
    display: block;
    float: right;
    padding-top: 0px;
  }
`;

export const ErrorMessage = styled.div`
  display: ${props => (props.show ? 'inherit' : 'none')};
  color: red;
  max-width: 300px;
`;

export const FullView = styled.div`
  display: ${props => (props.show ? 'initial' : 'none')};
`;

export const GeneralInfo = styled.div`
  margin: 11px 10px 11px 10px;
`;

export const Message = styled.div`
  margin-bottom: 10px;
  color: ${theme.body_secondary_text_color_default};
  word-break: break-word;
`;

export const StatusInfo = styled.div`
  font-size: ${theme.global_font_size_small};
  flex-wrap: wrap;
  color: ${theme.body_secondary_text_color_default};
`;

export const SummaryHeader = styled.div`
  font-weight: bold;
`;

export const SummaryActionDialogFooter = styled(DialogFooter)`
  && {
    display: inline-grid;
    float: left;
    clear: both;
    margin: 0;

    button {
      margin: 5px;
    }
  }
`;

export const SummaryHeaderInline = styled.div`
  font-weight: bold;
  display: inline-block;
  padding-right: 5px;
`;

/* For LinkStyledButton */
export const LinkStyledButton = styled(Button)`
  &&& {
    border: 0;
    color: ${theme.global_color_blue_500};
    padding: 0;
    font-size: ${theme.font_size_default};
    font-weight: normal;
    min-width: 50px;
    height: 20px;
    vertical-align: baseline;

    &:hover {
      background: none;
      text-decoration: underline;
    }

    &.focus-ring {
      background-color: transparent;
    }

    &.replace_link {
      span {
        padding: 0;
      }
    }

    &.replace-link-focus {
      span {
        padding: 0 5px;
      }
    }
  }

  .spectrum--dark &&& {
    color: #4b9cf5;
  }
`;

/**
 * Some radio buttons don't normal text labels such as : () Option A
 * Instead, the labels look like such: () Option A <SomeComponent such as Select, Textfield>
 * The z-index:1 ensures that these components get focus
 *
 */
export const RadioGroupsWithNonTextLabels = styled(RadioGroup)`
  .spectrum-Radio {
    margin-top: 10px;
    height: auto;
    align-items: baseline;

    .spectrum-Radio-button {
      top: 3px;
    }

    .spectrum-Radio-label {
      font-weight: normal;
      white-space: inherit;
      width: 100%;
      z-index: 1; /* Inputs in nested radio labels don't receive focus. */
      position: relative; /*Only positioned elements have z-indices. Otherwise z-index defaults to auto*/

      input,
      textarea {
        width: 100%;
      }
    }
  }
`;

/**
 * Override to spectrum-light.
 * Background: white, to overide spectrum-light background which is off white
 * overflow: auto, to ensure IE 11 browser does not have dialog content to overflow outside of parent container
 *           and still be able to scroll to dialog footer in higher zoom setting
 */
export const DialogWithWhiteBackground = styled(Dialog)`
  &&& {
    overflow: auto;
  }

  .spectrum--light &&& {
    background-color: white;

    .spectrum-Dialog-header,
    .spectrum-Dialog-footer {
      background-color: white;
    }
  }
`;

export const DoneCheckmark = styled(Checkmark)`
  && {
    float: right;
    margin-left: auto;
    color: ${theme.global_color_celery_600};
  }
`;

export const AlertIcon = styled(Alert)`
  &&&&& {
    color: ${props => (props.colored ? theme.semantic_negative_color_icon : 'inherit')};
  }
`;

export const AutoSavedDataIcon = styled(AssetModified)`
  height: 18px;
  width: 18px;
  margin-top: 1px;
`;

export const TextInput = styled(Textfield)`
  && {
    display: block;
    width: 100%;
    margin: 10px 0 0;

    & + button {
      margin: 10px 0 0;
    }
  }
`;

export const OverflowEllipsisHeaderText = styled.div`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`; /* used in member and group headers */

export const StyledParagraphBreakWord = styled.p`
  width: 100%;
  word-wrap: break-word;
`;

export const HelpButton = styled(Button)`
  width: 18px;
  height: 18px;
  position: relative;
`;

export const ViewProgressButton = styled(LinkStyledButton)`
  float: right;
  min-height: 23px;
`;

export const StyledHeading = styled(Heading).attrs(props => ({
  variant: props.variant || 'subtitle1'
}))`
  display: inline-block;
  padding-left: 10px;
`;

export const StyledDialogWithCTA = styled(DialogWithWhiteBackground)`
  &&&& {
    max-width: ${({ size }) => (size === 'M' ? '550px' : '650px')};
    min-width: 400px;

    @media only screen and (max-width: 480px) {
      max-width: 300px;
      min-width: auto;
    }

    .spectrum-Button--cta {
      padding: 0 10px;
      height: 32px;
      border: 0;

      &:disabled {
        cursor: default;
        pointer-events: none;
      }

      a {
        min-height: auto;
        background: transparent;
        color: white;
      }
    }
  }
`;

export const AsteriskAfter = styled.div`
  span:after {
    color: #e32;
    content: ' *';
    display: inline;
  }
`;

export const AsteriskBefore = styled.div`
  span:before {
    color: #e32;
    content: '* ';
    display: inline;
  }

  span {
    font-size: x-small;
    font-weight: bold;
  }
`;

export const StyledProgress = styled(Progress)`
  float: right;
  margin-top: 5px;

  .spectrum-BarLoader-track {
    width: 100px;
  }
`;

StyledDialogWithCTA.displayName = 'StyledDialogWithCTA';

export const StyledWaitForDownload = styled(Wait)`
  margin-right: 21px;
`;

export const LockIcon = styled(userLock)`
  && {
    color: ${theme.body_secondary_text_color_default};
    width: 18px;
    height: 17px;
    margin-left: 10px;
    vertical-align: middle;
  }
`;

export const RestrictedAgreementLockIcon = styled(userLock)`
  && {
    color: ${theme.body_secondary_text_color_default};
    width: 18px;
    height: 17px;
    margin-right: 5px;
    vertical-align: middle;
  }
`;

export const CCListView = styled.div`
  display: flex;
  margin-top: 5px;
  span {
    margin-left: 5px;
  }
`;
