import React from 'react';
import { observer } from 'mobx-react';
import ActivityReportIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_ActivityReport_18_N.svg';
import { analyticsFor } from 'utils/analytics';
import PostView from 'components/post-views';
import * as classNames from '../../classNames';
import { CONTEXT_BOARD_TYPES } from 'stores/constants';
import DownloadErrorReportView from 'components/download-error-report';
import DownloadCompletedAgreementsView from 'components/download-completed-agreements';

/**
 * Components for MegaSigns
 */

export const DownloadErrorReport = observer(props => {
  const isMegaSign = props.type === CONTEXT_BOARD_TYPES.MEGASIGN;
  const isDCWeb = props.stores.Env.isDCWeb;
  return isMegaSign && !isDCWeb ? <DownloadErrorReportView {...props} /> : null;
});

export const DownloadCompletedAgreements = observer(props => {
  // observable to react to
  props.stores.facets.agreement_type; // eslint-disable-line
  const isDCWeb = props.stores.Env.isDCWeb;
  const isMegaSign = props.type === CONTEXT_BOARD_TYPES.MEGASIGN;
  const isBulkDownloadFloodgateEnabled = props.stores.Floodgate.hasMegasignBulkDownloadEnabled();
  const isBulkDownloadRollout =
    props.stores.UserSettingsSearch.isSendInBulkV4NewBulkDownloadRollout();
  let isBulkDownloadAllowed = false;
  if (isBulkDownloadFloodgateEnabled && isBulkDownloadRollout && props.stores.facets.signed) {
    const completedChildren = props.stores.facets.signed;
    const bulkDownloadMaxSize =
      props.stores.UserSettingsSearch.getSendInBulkV4NewBulkDownloadMaxSizeMegabytes();
    const singlePdfSize = props.agreement.get('singlePdfDownloadSize');
    if (bulkDownloadMaxSize && singlePdfSize && completedChildren > 0) {
      // if max bulk download size isn't available or we can't
      // estimate the bulk download size, we won't allow it
      const projectedDownloadSize = completedChildren * singlePdfSize;
      if (projectedDownloadSize <= bulkDownloadMaxSize) {
        isBulkDownloadAllowed = true;
      }
    }
  }

  return isMegaSign && isBulkDownloadAllowed && !isDCWeb ? (
    <DownloadCompletedAgreementsView {...props} useSignedUrl={true} />
  ) : null;
});

export const MegaSignActivityReport = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.visibility.observable.visibility; // eslint-disable-line
  props.agreement.members.observable.senderInfo; // eslint-disable-line

  const participant = props.agreement.members.findParticipantBySelf(
    true,
    props.stores.Api.Agreements.Members.PERSONAS.ANY
  );

  //TODO When the sender has to sign first, but does not, none of the child
  //agreements have been sent so the activity report should not be shown.  The
  //status will be IN_PROCESS so we can't check that.  Need to use the count
  //of created children < count of children, but we don't have the count of
  //created children available yet.  That will be done in the 11.0 release.
  if (
    props.agreement.isExpired() ||
    props.agreement.isPrefill() ||
    props.agreement.isAuthoring() ||
    (participant && participant.get('self') && participant.get('hidden'))
  )
    return null;

  return (
    <PostView
      {...props}
      name={'ACTIVITY_REPORT'}
      analyticsEventType={analyticsFor.ACTIVITY_REPORT}
      className={classNames.MEGASIGN_ACTIVITY_REPORT}
      labelId={'actions.activity_report'}
      icon={<ActivityReportIcon />}
      openInNewTab={true}
      switchAcctWarning={false}
    />
  );
});
