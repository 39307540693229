import { action, observable, autorun } from 'mobx';
import { setImmediate } from 'timers';
import log, { logEvent } from 'utils/logger';
import Env from './env';
import signApi from './api';
import UserSettings from './userSettings';
import UserSettingsSearch from './userSettingsSearch';
import Floodgate from './floodgate';
import AccountSharing from './accountSharing';
import User from './user';

const isJest = typeof jest !== 'undefined' && jest.fn && jest.mock;

// FIXME -- remove -- rely on tabview for view switching
const View = observable({
  // observable properties:
  currentView: 'FULL'
});

let storesPromise,
  agreement,
  waitingPromises = [];

/**
 * Takes a Backbone model/collection and returns an observable that is bound
 * to 'change' ('update' for collection) events triggered by the model.
 *
 * To not react to certain changes, pass {silent: true} to the Backbone sync method (fetch(), etc.)
 *
 * For PUT/POST (save()) verbs, pass the attribute that is changing to the save() method so that
 * change event is fired, e.g.:
 *
 *     model.save({status: 'foo-bar'});
 *
 *  and NOT:
 *
 *     model.set('status', 'foo-bar');
 *     model.save();  // will not fire change
 *
 * It's also good practice to pass {wait: true} option as 2nd arg to save(attrs, options),
 * so that UI isn't updated until after sync returns successfully.
 *
 * @param model {Backbone.Model|Backbone.Collection}
 * @return {observable}
 */
let registeredModelCount = 0;
let changeCount = 0;

const getObservableModel = model => {
  const isModel = !!model.attributes; // otherwise it's a collection
  const name = isModel ? 'model' : 'collection';

  // existing observable on model?
  if (model.observable) {
    logEvent.info(`Re-using existing ${name} observable`, model.cid);
    return model.observable;
  }

  logEvent.info(
    `[${++registeredModelCount}] %cObservable ${name} registered`,
    'background-color:darkorange',
    model.cid,
    model.allowedCRUD,
    logEvent.verbose ? model.attributes || model.models : ''
  );

  const updater = function (mod, response, options) {
    // console.log(444,  this.type)
    logEvent.info(
      `[${++changeCount}] %cA ${name} changed`,
      'background-color:lightsalmon',
      this,
      model.cid,
      {
        changed: isModel ? Object.keys(model.changed) : response.changes,
        response,
        options
      }
    );
    options = options || response || {};

    // Honor silent -- Backbone fires 'sync' event even if silent
    if (options.silent === true) return;

    // set immediate to allow js-rest to construct nested models & collections!!
    // Create closure to preserve changes.
    (changed => {
      setImmediate(
        action(() => {
          Object.assign(localObservable, changed);
        })
      );
    })(model.changed || model);
  };

  // model or collection?
  let changeEvent = isModel ? 'change' : 'update';
  model.on(changeEvent, updater, { type: changeEvent });
  model.once('sync', updater, { type: 'sync' }); // update on initial sync

  let localObservable = observable.object(model.attributes || model);
  localObservable._dispose = () => {
    model.off(null, updater);
  };

  model.observable = localObservable;
  return localObservable;
};

let stores = {
  facets: observable({
    state: {
      SIGNED: 0
    },
    signed: 0
  }),

  powerAutomate: observable({
    powerAutomateCBVisible: false,
    powerAutomateCBType: ''
  }),

  metadata: observable({
    end_date: ''
  }),

  get agreement() {
    return agreement;
  },

  set agreement(agr) {
    if (agreement && agreement.cid === agr.cid) return; // no-op
    agreement = agr;
    logEvent.info('New agreement set', agreement.id);

    // register the agreement globally
    getObservableModel(agreement);
  },

  getObservableModel,

  Api: null,

  Env,
  Floodgate: new Floodgate(),
  signApi,

  accountSharing: new AccountSharing(),

  // made available in App.js once locale is loaded
  Intl: {},
  View,
  autorun, // exposed to test harness

  // Api ready callback -- handle tasks after Api is ready.
  //
  // NOTE: Components should call stores.signApi.ready() directly.
  //
  // @private
  _onApiReady: Api => {
    stores.Api = Api;
    if (Api.is_new || !stores.UserSettings || stores.UserSettings.rejected) {
      stores.UserSettings = new UserSettings(Api);
      if (Env.loggedIn) stores.UserSettings.fetch();
      Api.is_new = false;
    }

    if (Api.is_new || !stores.User || stores.User.rejected) {
      stores.User = new User(Api);
      if (Env.loggedIn) stores.User.fetch();
      Api.is_new = false;
    }

    if (Api.is_new || !stores.UserSettingsSearch || stores.UserSettingsSearch.rejected) {
      stores.UserSettingsSearch = new UserSettingsSearch(Api);
      if (Env.loggedIn) stores.UserSettingsSearch.fetch();
      Api.is_new = false;
    }

    return Promise.all([
      stores.UserSettings.ready(),
      stores.User.ready(),
      stores.UserSettingsSearch.ready()
    ]);
  },

  /**
   * returns a promise that resolves or rejects when Store is ready.
   * @return {Promise<unknown>}
   */
  whenReady: () =>
    new Promise((res, rej) => {
      waitingPromises.push({ resolve: res, reject: rej });
    }),

  /**
   * exposing Jest helper function
   * @return {}
   */
  resolveWaiting: () => waitingPromises.map(p => p.resolve()),

  /**
   * Returns UnresolvedBounceIndicatorFeatureAvailable value from User or Group level
   */
  isUnresolvedBounceIndicatorFeatureAvailable: () => {
    const settingsClass = stores.accountSharing.shouldShowBanner()
      ? stores.agreementGroupSettings
      : stores.UserSettingsSearch;

    return settingsClass.isUnresolvedBounceIndicatorFeatureAvailable();
  }
};

/**
 * Aggregate stores ready - wait for all dependencies
 *
 * @return {Promise<any[]>}
 * @singleton
 */
stores.ready = () => {
  return (storesPromise =
    storesPromise ||
    Promise.all([
      // first batch -- have no dependencies on each other
      signApi.ready(),
      stores.Floodgate.ready(),
      stores.Env.ready()

      // other parallel Promises
      // here...
    ])
      // resolve waiting promises
      .then(stores.resolveWaiting));
};

if (!isJest) {
  // expose to parent app (primarily for test harness)
  Env.plugin.stores = stores;
  Env.plugin.log = log;
}

export default stores;
