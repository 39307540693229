import React, { Component, Fragment } from 'react';
import { computed } from 'mobx';
import { WithToastMessage } from 'as-ducati-core';
import { observer, inject } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getParticipants, isAgreementViewRestrictionApplied } from 'utils/helper';
import * as classNames from 'context-boards/classNames';
import { TOGGLE_SUMMARY_HEIGHT } from 'stores/constants';
import Env from 'stores/env';
import GroupName from 'common/groupName';
import ExpirationDate from 'components/expiration-date';
import EndDate from 'components/end-date';
import { SummaryInfo, SharedInfo, AgreementMessage } from 'components/summary-info';
import { ToggleHeight } from 'common/toggle-content';
import { GeneralInfo, Message, SummaryHeaderInline } from 'common/styledElements';
import SummaryActions from './summary-actions';

const COMPONENT_NAME = 'agreement-summary-info';
const WIDGET_INSTANCE = 'WIDGET_INSTANCE';

@injectIntl
@inject('agreement', 'eventful', 'stores')
@observer
class AgreementSummary extends Component {
  get agreementDetail() {
    return this.getAgreementDetail();
  }

  get agreement() {
    return this.props.agreement;
  }

  get members() {
    return this.agreement.members;
  }

  get agreementInfo() {
    return this.agreement.attributes;
  }

  constructor(props) {
    super(props);
    this.observable = this.props.stores.getObservableModel(this.members);
  }

  canRender() {
    if (this.agreement.isArchived()) return true;
    return this.members && this.members.get('participantSets').size() > 0;
  }

  componentDidUpdate() {
    this.props.eventful.fireUpdate({ component: COMPONENT_NAME });
  }

  /**
   * Agreement Status is calculated based on the participants
   * If the participantSet status is either CANCELLED OR COMPLETED, we use status returned
   * from GET /agreements/{agreementID} API call else from the participantSet.
   *
   * @returns {String} agreementStatus
   */
  @computed
  get agreementStatus() {
    let participantSets = this.members.get('participantSets'),
      agreementStatus;

    const myself = this.members.getMyself({ isMyTurn: true });

    // observable to react to
    this.props.agreement.observable.status; // eslint-disable-line
    this.observable.participantSets; // eslint-disable-line

    if (
      this.agreement.isCanceled() ||
      (participantSets.length > 0 && this.members.isMyTurnCompleted(participantSets.at(0))) ||
      (this.agreement.members.isSender() && myself && myself.get('status') === 'REPLACED') ||
      this.props.stores.accountSharing.isAcctSwitched()
    ) {
      agreementStatus = this.agreement.get('status');
    } else {
      agreementStatus = this.members.getAgreementStatusFromNextParticipantSet();
    }
    return agreementStatus;
  }

  agreementSender(sender) {
    return (
      <GeneralInfo className={classNames.AGREEMENT_INFO_SENDER}>
        <SummaryHeaderInline>
          <FormattedMessage id="summary_info.from" />:
        </SummaryHeaderInline>
        {sender}
      </GeneralInfo>
    );
  }

  getAgreementMessage() {
    const agreementMessage = this.agreementInfo.message;
    return agreementMessage ? (
      <GeneralInfo className={classNames.AGREEMENT_INFO_SENDER_MESSAGE}>
        <SummaryHeaderInline>
          <FormattedMessage id="summary_info.message" />:
        </SummaryHeaderInline>
        <AgreementMessage text={agreementMessage} />
      </GeneralInfo>
    ) : null;
  }

  getPrivateMessage() {
    const myself =
        this.isWidgetInstance(this.props) &&
        this.props.stores.Floodgate.hasWebformCcPrivateMsgEnabled() &&
        this.members.isCC()
          ? this.members.get('ccsInfo').findWhere({ self: true })
          : this.members.getMyself(),
      privateMessage = myself ? myself.get('privateMessage') : null;

    return privateMessage ? (
      <GeneralInfo className={classNames.AGREEMENT_PRIVATE_MESSAGE}>
        <SummaryHeaderInline>
          <FormattedMessage id="summary_info.private_message" />:
        </SummaryHeaderInline>
        <AgreementMessage text={privateMessage} />
      </GeneralInfo>
    ) : null;
  }

  isWidgetInstance(props) {
    return props.agreement.get('type') === WIDGET_INSTANCE;
  }

  getAgreementDetail() {
    const senderName = this.members.get('senderInfo').get('name'),
      senderEmail = this.members.get('senderInfo').get('email'),
      sender = senderName ? senderName + ' (' + senderEmail + ')' : senderEmail,
      agreementSender =
        this.agreement.isArchived() || (this.agreement.members.isSender() && !Env.hasSharer())
          ? null
          : this.agreementSender(sender),
      agreementMessage = this.getAgreementMessage(),
      privateMessage = this.getPrivateMessage(),
      expirationDate = this.getExpirationDate(),
      endDate = this.getEndDate(),
      ccParticipants = getParticipants(
        {
          apiResponseKey: 'ccsInfo',
          className: classNames.AGREEMENT_INFO_CC,
          headerId: 'summary_info.cc'
        },
        this.members,
        {
          componentName: COMPONENT_NAME,
          eventful: this.props.eventful
        }
      ),
      toParticipants = getParticipants(
        {
          apiResponseKey: 'participantSets',
          className: classNames.AGREEMENT_INFO_TO,
          headerId: 'summary_info.to',
          role: 'SHARE'
        },
        this.members,
        {
          componentName: COMPONENT_NAME,
          eventful: this.props.eventful
        }
      );

    return (
      <Fragment>
        <SummaryInfo
          name={this.agreementInfo.name}
          createdDate={this.agreementInfo.createdDate}
          isRestricted={isAgreementViewRestrictionApplied()}
        />
        {agreementSender}
        {toParticipants}
        {expirationDate}
        <GeneralInfo>
          <SummaryHeaderInline>
            <FormattedMessage id="summary_info.status" />:
          </SummaryHeaderInline>
          <span className={classNames.AGREEMENT_INFO_STATUS}>
            {this.props.stores.Api.i18n.js[this.agreementStatus.toLowerCase()]}
          </span>
        </GeneralInfo>
        {endDate}
        <GroupName isCreator={this.agreement.members.isSender()} />
        {this.agreement.isArchived() ? (
          <Fragment>{agreementMessage}</Fragment>
        ) : (
          <Fragment>
            <Message>
              {agreementMessage}
              {privateMessage}
            </Message>
            {ccParticipants}
            <SharedInfo {...this.props} members={this.members} componentName={COMPONENT_NAME} />
          </Fragment>
        )}
      </Fragment>
    );
  }

  getExpirationDate() {
    if (this.agreement.isComplete() || this.agreement.isIncomplete()) return null;

    return <ExpirationDate />;
  }

  getEndDate() {
    if (
      this.agreement.isComplete() &&
      this.agreement.members.isSender() &&
      this.props.stores.Env.loggedIn &&
      this.props.stores.UserSettings.canEditAgreementEndDate()
    ) {
      return <EndDate />;
    }
    return null;
  }

  render() {
    // observable to react to
    this.props.agreement.observable.status; // eslint-disable-line
    this.props.agreement.observable.expirationTime; // eslint-disable-line
    this.observable.participantSets; // eslint-disable-line

    if (!this.canRender()) return null;

    return (
      <Fragment>
        <ToggleHeight
          className={classNames.SUMMARY_INFO_SECTION}
          analyticsSubType="summary"
          height={TOGGLE_SUMMARY_HEIGHT}
          style={{ marginBottom: '1ex' }}
          eventful={this.props.eventful}
        >
          {this.agreementDetail}
        </ToggleHeight>
        <SummaryActions {...this.props} membersObservable={this.observable} />
      </Fragment>
    );
  }
}

export default WithToastMessage(AgreementSummary);
