import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { inject } from 'mobx-react';
import { action } from 'mobx';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import { WithToastMessage } from 'as-ducati-core';
import throttle from 'lodash/throttle';
import ActionButton from 'common/actionButton';
import { StyledWaitForDownload, StyledDialogWithCTA } from 'common/styledElements';
import withUtil from 'common/withUtil';
import { sanitizeFileName } from 'utils/helper';
import { analyticsFor } from 'utils/analytics';

const SaveAsActionButton = props => {
  return (
    <ActionButton
      element="a"
      quiet
      title={props.title}
      disabled={props.disabled}
      className={props.className}
      aria-label={props.ariaLabel}
      label={props.label}
      icon={props.icon}
      innerRef={props.innerRef}
      href={props.href}
      requireLogin={props.requireLogin}
      openComponent={props.openComponent}
      download={props.download}
      analytics={props.analytics}
      onClick={props.onClick}
    />
  );
};

// IE11 hack
let asBlob = !('download' in HTMLAnchorElement.prototype) && 'msSaveOrOpenBlob' in window.navigator;

let getFileName = props =>
  sanitizeFileName(props.fileName || props.model.get('name') || new Date().toISOString()) +
  (props.extension || '');

/**
 * SaveAs component -- shows an action button.  When clicked,
 * it fetches the model's data from the server and saves it as a file.
 *
 * @param props.model {Backbone.Model} one of the "Api.Helpers.Streamable" models with a fetch() method
 *   and a "value" and "url" attributes.
 * @param props.callOptions {object|function} - any model attributes for model.fetch()/save()
 * @param props.fileName {string} - file name to save as
 * @param props.extension {string} - file extension, e.g., ".pdf"
 * @param props.label {string} - the button label
 * @param props.labelId {string} - of the label intl id
 * @param props.icon {Component} - the icon to render
 * @param props.analytics {Analytics} - analytics instance
 * @param props.analyticsEventType {string} - or the analytics type string
 * @param props.type {string} - 'fetch' or 'save'
 * @param props.requireLogin {boolean} -
 * @param props.useSignedUrl {boolean} - if 'true', the model endpoint will fetch a signed url and then invoke that for download
 * @param throttle {boolean|numeric} falsy to disable, otherwise throttle time in msec
 * @param onSuccess {Function} on success callback function
 * @param onError {Function} on error callback function
 * @param onClick {function} on click handler function when saveAs confirm button clicked. If this fn returns false, saveAs doesn't continue to execute.
 */
@inject('stores')
class SaveAs extends Component {
  constructor(props) {
    super(props);
    if ('requireLogin' in props) this.requireLogin = props.requireLogin;

    this.callOptions = {
      useBlob: asBlob,
      ...(typeof props.callOptions === 'object' && props.callOptions)
    };

    // throttle the clicks on the anchor tag once href (blob) is filled in
    // return true to allow click, false otherwise.
    this.waiting = false;
    if (this.props.throttle) {
      this.clickHandler = throttle(() => (this.waiting = false), this.props.throttle, {
        trailing: true
      });
    }
    this.linkRef = React.createRef();
    this.dialogRef = React.createRef();
  }

  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._strings = this._strings || {
      downloadLabel: formatMessage({ id: 'common.download' }),
      cancelLabel: formatMessage({ id: 'cancel.title' }),
      downloadConfirmationMessage: formatMessage({ id: 'download.confirmation_message' })
    });
  }

  render() {
    const container = window.document.body;
    const { formatMessage } = this.props.stores.Intl;
    const label = this.props.label || formatMessage({ id: this.props.labelId });
    const disabled = !!this.props.error || this.props.waiting; // this.props.waiting is defined in withUtil HOC
    this.fileName = getFileName(this.props);
    this.analytics =
      this.props.analytics || analyticsFor(this.props.analyticsEventType || analyticsFor.SAVE_AS);

    if (this.props.useSignedUrl) {
      return (
        <ModalTrigger container={container}>
          <SaveAsActionButton
            {...this.props}
            title={''}
            disabled={false}
            label={label}
            icon={this.props.icon}
            innerRef={this.linkRef}
            href={null}
            download={this.fileName}
            analytics={this.analytics}
          />
          <StyledDialogWithCTA
            backdropClickable={false}
            container={window.document.body}
            onConfirm={() => this.onClick(null)}
            cancelLabel={this.strings.cancelLabel}
            confirmLabel={this.strings.downloadLabel}
            ref={this.dialogRef}
            title={label}
          >
            <div>{this.strings.downloadConfirmationMessage}</div>
          </StyledDialogWithCTA>
        </ModalTrigger>
      );
    } else {
      return (
        <SaveAsActionButton
          {...this.props}
          title={this.props.error ? label : ''}
          disabled={disabled}
          label={this.props.error || label}
          icon={this.props.waiting ? <StyledWaitForDownload /> : this.props.icon}
          innerRef={this.linkRef}
          href={this.props.href || ''}
          requireLogin={this.requireLogin}
          download={this.fileName}
          analytics={this.analytics}
          onClick={e => this.onClick(e)}
        />
      );
    }
  }

  /**
   * Helper method for fetching the download save action promise, which returns
   * a signed URL, stream or blob.  Can be one of the following:
   * - (1) signing url (GET) - calling the server will return a signing url for
   *   a subsequent server call that streams a blob
   * - (2) non-signing URL (POST) - calling the server will return a blob
   * - (3) non-signing URL (GET) - calling the server will return a blob
   */
  getCallPromise() {
    if (this.props.useSignedUrl) {
      // (1) signing url (GET) - calling the server will return a signing url for
      // a subsequent server call that streams a blob
      return this.model.fetchAsJSON(this.callOptions);
    } else if (this.props.type === 'save') {
      // (2) non-signing URL (POST) - calling the server will return a blob
      return this.model.save(this.callOptions, { useBlob: asBlob });
    } else {
      // (3) non-signing URL (GET) - calling the server will return a blob
      return this.model.fetch(this.callOptions);
    }
  }
  /**
   * Handler for when <download> button is clicked.
   * Fetch model Blob & replace href
   * @param {Event} e
   */
  onClick(e) {
    if (this.props.onClick && !this.props.onClick()) {
      e && e.preventDefault();
      return false;
    }

    if (typeof this.props.callOptions === 'function') {
      Object.assign(this.callOptions, this.props.callOptions());
    }
    this.model = this.props.model;

    // change from value to url since url is always updated, XLS documents dont see to have a value.
    const haveData =
      !!this.model.get('url') && this.linkRef.current.buttonRef.href.indexOf('blob') !== -1;
    this.target = this.linkRef.current;

    if (haveData && !asBlob) return; // normal browsers
    e && e.preventDefault();

    // throttle handler
    if (this.clickHandler) {
      this.target.onclick = () => {
        this.clickHandler(); // throttled
        if (this.waiting) return false;
        this.waiting = true;
      };
    }

    if (haveData) {
      // save as blob (MS IE/Edge)
      this.saveAs();
    } else {
      this.props.setWaiting(true);
      const promise = this.getCallPromise();

      this.analytics.timeMark();
      promise
        .then(() => {
          this.props.setWaiting(false);
          this.analytics.success(this.props.analyticsSubType);
          this.saveAs();
          this.props.onSuccess && this.props.onSuccess();
        })
        .catch(error => {
          this.props.setWaiting(false);
          this.analytics.failed(this.props.analyticsSubType, error);
          this.props.onError ? this.props.onError(error) : this.props.showToast(error);
          return false;
        });
    }
  }

  @action
  saveAs() {
    if (asBlob) {
      window.navigator.msSaveOrOpenBlob(this.model.get('value'), this.fileName);
    } else if (this.props.useSignedUrl) {
      var a = document.createElement('a');
      a.setAttribute('href', this.model.get('value').url);
      a.setAttribute('download', this.fileName);
      a.click();
    } else if (this.linkRef && this.linkRef.current) {
      let button = ReactDOM.findDOMNode(this.linkRef.current); // actual button dom element
      button.href = this.model.get('url');
      // Click needs to be called on next tick
      setTimeout(() => button.click(), 0);
    } else {
      // Add analytics to identify the cause of the error.
      this.analytics.send(this.props.analyticsSubType, 'saveAs-null-error', this.linkRef);
    }
  }
}

SaveAs.defaultProps = {
  type: 'fetch',
  actionButton: true,
  throttle: 1000 // allow click at most once per sec.
};

export default WithToastMessage(withUtil(SaveAs));
