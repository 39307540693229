import logger from '../utils/logger';

const FETCH_SETTINGS = [
  'unresolvedBounceIndicatorFeatureAvailable',
  'liveFormFieldsDataEnabled',
  'witnessReplaceAvailable',
  'privateRecipientEnabled',
  'agreementDeliveryMethods'
];

// settings that are suspect and need to be fetched separately
const FETCH_SUSPECT_SETTINGS = [
  'sendInBulkV4NewBulkDownloadRollout',
  'sendInBulkV4NewBulkDownloadMaxSizeMegabytes'
];

/**
 * Settings for user: /user/me/settings/search
 *
 */
export default class UserSettingsSearch {
  constructor(Api) {
    // setting default values
    this.settings = new Api.Users({ id: 'me' }).search;

    // Override to "add" to the list collection since we are making multiple requests
    this.settings.parse = function (response) {
      this.list.set(response.settings, {
        remove: false,
        merge: false
      });
      // Return null as there are no attributes to set on this model.
      return null;
    };

    this.promise = null;
    this.rejected = false;
  }

  fetch(body) {
    this.promise = this.settings
      .save(body || { settings: FETCH_SETTINGS })
      .then(() => {
        // Do not call fetchSuspectSettings if body is provided (circular call
        if (body || !FETCH_SUSPECT_SETTINGS.length) {
          return this;
        }
        return this.fetchSuspectSettings();
      })
      .catch(e => {
        // the failure is caught and not thrown again, so the context board may render
        // even in this scenario.
        this.rejected = true;
        const errMsg = 'Unable to get user settings search';
        console.warn(errMsg, { error: e });
        logger.error(errMsg, { error: e });
      });
    return this.promise;
  }

  fetchSuspectSettings() {
    return this.fetch({ settings: FETCH_SUSPECT_SETTINGS });
  }

  ready() {
    return this.promise;
  }

  /* insert comment here
   * Checks whether unresolvedBounceIndicatorFeatureAvailable setting is true or false; if it does not exist
   * default to return false
   * Returns: bool
   */
  isUnresolvedBounceIndicatorFeatureAvailable() {
    return this.settings.list.getByName('unresolvedBounceIndicatorFeatureAvailable');
  }

  isLiveFormFieldDataFeatureEnabled() {
    return this.settings.list.getByName('liveFormFieldsDataEnabled');
  }

  isWitnessReplaceAvailable() {
    return this.settings.list.getByName('witnessReplaceAvailable');
  }

  isPrivateRecipientEnabled() {
    return this.settings.list.getByName('privateRecipientEnabled');
  }

  isSmsDeliveryEnabled() {
    const agreementDeliveryMethods = this.settings.list.getByName('agreementDeliveryMethods');
    return agreementDeliveryMethods && agreementDeliveryMethods.includes('SMS');
  }

  isSendInBulkV4NewBulkDownloadRollout() {
    return this.settings.list.getByName('sendInBulkV4NewBulkDownloadRollout');
  }

  getSendInBulkV4NewBulkDownloadMaxSizeMegabytes() {
    return this.settings.list.getByName('sendInBulkV4NewBulkDownloadMaxSizeMegabytes');
  }
}
